<template>
    <div id="wrapper">
        <navbar id="nav" class="animate__animated animate__fadeInLeft no-print"  style="display: none;"/>
        <div class="d-flex flex-column animate__animated animate__fadeInRight" id="content-wrapper" style="display: none !important;">
            <div id="content">
                <header-btn/>
                <div class="container-fluid">
                    <h3 class="text-dark multi-language"> {{stafflanguage[language].staffs}} </h3>
                    <hr>
                    <div class="row" style="overflow-x: auto;">
                        <div class="mb-2 p-1"> 
                            <input type="text" class="form-control " v-bind:placeholder=" stafflanguage[language].search + '...'" v-model="search">
                        </div>
                        <button class="btn btn-primary mb-2 mx-1" onclick="window.print()"> Print <i class="fa fa-print"></i> </button>
                        <table class="table table-bordered table-striped text-dark text-center " >
                            <thead>
                                <th style="width: 15%;"> {{stafflanguage[language].ID}} </th>
                                <th style="width: 30%;">  {{stafflanguage[language].staff_name}} </th>
                                <th style="width: 30%;"> {{stafflanguage[language].en_name}} </th>
                                <th style="width: 25%;" class="no-print"> {{stafflanguage[language].employees}} </th>
                            </thead>
                            <tbody>
                                <tr v-for="staff in filtered_staffs" :key="staff.st_id">
                                    <td> {{ staff.st_id }} </td>
                                    <td> {{ staff.staff_name }} </td>
                                    <td> {{ staff.engineer }} </td>
                                    <td class="no-print"> <button class="btn btn-primary" v-on:click="st_id = staff.st_id" data-toggle="modal" data-target="#mdl_employees"> {{stafflanguage[language].emp_btn}} </button> </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modals -->
        <div class="modal animate__animated animate__fadeInDown animate__faster" id="mdl_employees">
            <div class="modal-dialog modal-xl text-dark">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="btn btn-danger" data-dismiss="modal"> &times; </button>
                        <h5 class="mt-1"> {{stafflanguage[language].employees}} </h5>
                    </div>
                    <div class="modal-body" style="overflow-x: auto;">
                        <table class="table table-striped table-bordered text-center text-dark" >
                            <thead>
                                <th> {{stafflanguage[language].ID}} </th>
                                <th> {{stafflanguage[language].emp_name}} </th>
                                <th style="width: 25%;"> {{stafflanguage[language].salary_type}}  </th>
                                <th> {{stafflanguage[language].salary}} </th>
                                <th> {{stafflanguage[language].food_money}} </th>
                                <th> {{stafflanguage[language].transport_money}} </th>
                                <th> {{stafflanguage[language].status}} </th>
                            </thead>
                            <tr v-for="employee in filtered_employees" :key="employee.emp_id">
                                <td> {{ employee.emp_id }} </td>
                                <td> {{ employee.first_name + ' ' + employee.last_name }} </td>
                                <td> {{ employee.salary_type | salary_type_filter(language) }} </td>
                                <td v-if="employee.salary_type == 'Daily'"> {{ employee.daily_salary }} IQD </td>
                                <td v-else> {{ employee.monthly_salary }} $ </td>
                                <td> {{ employee.food_money }} $ </td>
                                <td> {{ employee.transport_money }} $ </td>
                                <td v-bind:class="{ 'text-success': employee.active_status == 1, 'text-danger': employee.active_status == 0 }"> {{ employee.active_status | status_filter(language) }} </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import auth from '../../auth';
    import stafflanguage from '../../languages/staff_report.js'
    import { Bus } from '../../Bus.js'
    import $ from 'jquery'

    export default {
        name: "staffReport",
        data() {
            return {
                staffs: [],
                employees: [],
                user: {
                    en_id: null
                },
                search: "",
                st_id: null,
                stafflanguage,
                language:'English'
            }
        },
        beforeCreate() {
            auth("staffReport");
        },
        created() {
            this.$http.post("/getLoggedInfo").then(({data}) => {
                this.user.username = data.username;
                this.user.role = data.type;
                this.user.en_id = data.en_id || null;
                
                this.$http.post("/staff/getStaffReport", {
                    en_id: this.user.en_id
                }).then(({data}) => {
                    this.staffs = data.staffs;
                    this.employees = data.employees;
                });
            });
        },
        computed: {
            filtered_staffs() {
                return this.staffs.filter(obj => {
                    return obj.st_id == this.search ||
                           obj.staff_name.match(this.search) ||
                           obj.engineer.match(this.search);
                });
            },
            filtered_employees() {
                return this.employees.filter(obj => obj.st_id == this.st_id);
            }
        },
        mounted(){
            Bus.$on('languageChanged',(data)=>{
            this.language=data
              if(["Kurdish", "Arabic"].includes(data)){
                 $(".multi-language").addClass("rtl");
                 $("#wrapper").attr("dir", "rtl")
              } else {
                 $(".multi-language").removeClass("rtl");
                 $("#wrapper").attr("dir", "ltr")
                }
            })
        },
        filters: {
            salary_type_filter(value,language) {
                if(value == "Monthly"){
                    return stafflanguage[language].monthly;
                }
                return stafflanguage[language].daily;
            },
            status_filter(value, language){
                return [
                    stafflanguage[language].deactive,
                    stafflanguage[language].active
                ][value];
            }
        }
    }
</script>

<style scoped>
    @font-face {
        font-family: nrt;
        src: url("../../assets/fonts/NRT-Reg.ttf");
    }
    table, h3, h5 {
        font-family: nrt;
    }
    @media print {
        input, button, .no-print {
            display: none !important;
        }
    }
    .rtl {
    text-align: right !important;
    }
</style>